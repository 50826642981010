import { useCallback, useMemo } from 'react';

import notification from '../common/function/notification';
import { useDevContext } from '../contexts/devContext';
import { useAvailableContext } from '../contexts/availableContext';

const useFetch = () => {
	const { isDev } = useDevContext();
	const { setIsAvailable } = useAvailableContext();
	const abortController = useMemo(() => new AbortController(), []);

	const processingResponse = useCallback(
		(response) => {
			if (response) {
				if (response.goTo) {
					if (response.reload) {
						if (response.goTo === '/user/login/') {
							window.location.replace('/login');
						} else {
							window.location.replace(response.goTo);
						}
					} else if (response.goTo === '/user/login/') {
						window.location.replace('/login');
					} else {
						window.location.replace(response.goTo);
					}
				} else if (response.reload) {
					window.location.reload();
				} else if (response.error) {
					if (response.error !== 'Не найдено') {
						showError('Ошибка', response.error);
					}
				}
			}
		},
		[showError],
	);

	const showError = useCallback((title, text) => {
		notification({
			icon: 'error',
			color: 'danger',
			title,
			text,
		});
	}, []);

	const fetchErrorHandler = useCallback(
		(error, title, text, onError) => {
			if (error.name !== 'AbortError') {
				try {
					error
						.json()
						.then((reason) => {
							if (reason.goTo) {
								setIsAvailable(true);
								if (reason.reload) {
									window.location.replace(reason.goTo);
								} else if (reason.goTo === '/user/login/') {
									window.location.replace('/login');
								} else {
									window.location.replace(reason.goTo);
								}
							} else if (reason.error === '404 Not Found') {
								setIsAvailable(true);
								window.location.replace('/404', { replace: true });
							} else if (reason.error === '503 Service Unavailable') {
								setIsAvailable(false);
								showError('Ошибка', 'Технические работы на сервере');
							} else if (typeof onError === 'function') {
								setIsAvailable(true);
								onError(reason);
							} else {
								setIsAvailable(true);
								showError(title, reason.error || text);
							}
						})
						.catch(() => {
							setIsAvailable(true);
							showError('Ошибка', 'Некорректный ответ сервера');
						});
				} catch (err) {
					setIsAvailable(true);
					showError('Ошибка', `${err}`);
					if (typeof onError === 'function') {
						onError();
					}
				}
			}
		},
		[setIsAvailable, showError],
	);

	const sendData = useCallback(
		(url, body, controller = abortController, onError = null, header = {}) => {
			return fetch(
				url.startsWith('http')
					? url
					: `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${url}`,
				{
					signal: controller.signal,
					method: 'POST',
					headers: !(body instanceof FormData)
						? {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								...header,
						  }
						: {...header},
					body,
				},
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					setIsAvailable(true);
					processingResponse(data);
					return data;
				})
				.catch((error) => {
					fetchErrorHandler(
						error,
						'Ошибка получения данных',
						'Данные с сервера не получены',
						onError,
					);
				})},
		[abortController, fetchErrorHandler, isDev, processingResponse, setIsAvailable],
	);

	const putData = useCallback(
		(url, body, controller = abortController, onError = null, header = {}) =>
			fetch(
				url.startsWith('http')
					? url
					: `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${url}`,
				{
					signal: controller.signal,
					method: 'PUT',
					headers: !(body instanceof FormData)
						? {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								...header,
						  }
						: {...header},
					body,
				},
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					setIsAvailable(true);
					processingResponse(data);
					return data;
				})
				.catch((error) => {
					fetchErrorHandler(
						error,
						'Ошибка получения данных',
						'Данные с сервера не получены',
						onError,
					);
				}),
		[abortController, fetchErrorHandler, isDev, processingResponse, setIsAvailable],
	);

	const patchData = useCallback(
		(url, body, controller = abortController, onError = null, header = {}) =>
			fetch(
				url.startsWith('http')
					? url
					: `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${url}`,
				{
					signal: controller.signal,
					method: 'PATCH',
					headers: !(body instanceof FormData)
						? {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								...header,
						  }
						: {...header},
					body,
				},
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					setIsAvailable(true);
					processingResponse(data);
					return data;
				})
				.catch((error) => {
					fetchErrorHandler(
						error,
						'Ошибка получения данных',
						'Данные с сервера не получены',
						onError,
					);
				}),
		[abortController, fetchErrorHandler, isDev, processingResponse, setIsAvailable],
	);

	const deleteData = useCallback(
		(url, body, controller = abortController, onError = null, header = {}) =>
			fetch(
				url.startsWith('http')
					? url
					: `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${url}`,
				{
					signal: controller.signal,
					method: 'DELETE',
					headers: !(body instanceof FormData)
						? {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								...header,
						  }
						: {...header},
					body,
				},
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					setIsAvailable(true);
					processingResponse(data);
					return data;
				})
				.catch((error) => {
					fetchErrorHandler(
						error,
						'Ошибка получения данных',
						'Данные с сервера не получены',
						onError,
					);
				}),
		[abortController, fetchErrorHandler, isDev, processingResponse, setIsAvailable],
	);

	const fetchData = useCallback(
		(url, controller = abortController, onError = null, header = {}) =>
			fetch(
				url.startsWith('http')
					? url
					: `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${url}`,
				{
					signal: controller.signal,
					headers: header,
				},
				
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					setIsAvailable(true);
					processingResponse(data);
					return data;
				})
				.catch((error) => {
					fetchErrorHandler(
						error,
						'Ошибка получения данных',
						'Данные с сервера не получены',
						onError,
					);
				}),
		[abortController, fetchErrorHandler, isDev, processingResponse, setIsAvailable],
	);

	return {
		fetchData,
		sendData,
		putData,
		patchData,
		deleteData,
		showError,
		fetchErrorHandler,
	};
};

export default useFetch;
