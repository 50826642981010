import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';
import { is, validate, object } from 'superstruct';

import notification from '../common/function/notification';
import { UserData } from '../types/User';
import { useDevContext } from './devContext';

const User = object({
	user: UserData,
});

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage('token');
	const [token, setToken] = useState(storageToken);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { isDev } = useDevContext();

	const controller = useMemo(() => new AbortController(), []);
	const sendedToken = useMemo(() => searchParams.get('token'), [searchParams]);
	const authChannel = useMemo(
		() => ('BroadcastChannel' in window.self ? new BroadcastChannel('auth') : null),
		[],
	);

	useEffect(() => {
		if (sendedToken) {
			setToken(sendedToken);
			setStorageToken(sendedToken);
			setSearchParams({}, { replace: true });
		}
	}, [sendedToken, setSearchParams, setStorageToken]);

	// Broadcast Channel API, обработчик сообщений
	useEffect(() => {
		if (authChannel) {
			authChannel.addEventListener('message', (event) => {
				if (event.origin === process.env.REACT_APP_DOMAIN_NAME) {
					if (event.data) {
						const from = location.state?.from?.pathname || '/';
						setToken(event.data);
						setStorageToken(event.data);
						navigate(from, { replace: true });
					} else if (event.data === '') {
						setToken(null);
						setUser(null);
					}
				}
			});
		}
	}, [authChannel, location.state?.from?.pathname, navigate, setStorageToken, token]);

	// Если есть токен получаем данные пользователя
	useEffect(() => {
		if (token) {
			getUserData();
		}
	}, [token, getUserData]);

	useEffect(() => {
		if (token && location.pathname === '/login') {
			const from = location.state?.from?.pathname || '/';
			navigate(from, { replace: true });
		}
	}, [location.pathname, location.state?.from?.pathname, navigate, token]);

	const showError = useCallback((title, text) => {
		notification({
			icon: 'Error',
			color: 'danger',
			title,
			text,
		});
	}, []);

	const requestUrl = useCallback(
		(url, callback) => {
			fetch(url, {
				signal: controller.signal,
			})
				.then((response) =>
					// eslint-disable-next-line no-nested-ternary
					response.ok
						? response.json()
						: response.redirected
						? requestUrl(response.url, callback)
						: Promise.reject(response),
				)
				.then((answer) => {
					// eslint-disable-next-line no-console
					console.log(answer);
					if (answer?.goTo) {
						if (answer.goTo.includes('http')) {
							window.location.replace(answer.goTo);
						} else {
							requestUrl(
								`${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${
									answer.goTo
								}`,
								callback,
							);
						}
					} else {
						setUser(answer);
						callback(true);
					}
				})
				.catch((error) => {
					error.json().then((answer) => {
						if (answer?.goTo) {
							if (answer.goTo.includes('http')) {
								window.location.replace(answer.goTo);
							} else {
								requestUrl(
									`${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${
										answer.goTo
									}`,
									callback,
								);
							}
						}
					});
				});
		},
		[controller.signal, isDev],
	);

	// Получаем данные пользователя
	const getUserData = useCallback(
		(callback) => {
			if (token) {
				fetch(
					`${
						isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL
					}/users/?token=${token}`,
					{
						signal: controller.signal,
					},
				)
					.then((response) => (response.ok ? response.json() : Promise.reject(response)))
					.then((userData) => {
						if (userData && typeof userData === 'object') {
							if (userData.goTo) {
								if (userData.reload) {
									window.location.assign(userData.goTo);
								} else {
									navigate(userData.goTo);
								}
							} else if (userData.user !== null) {
								if (is(userData, User)) {
									setUser(userData);

									if (typeof callback === 'function') {
										callback(true);
									}
								} else {
									const { message } = validate(userData, User)[0];
									console.error(message);
									showError('Неверная структура данных', message);
								}
							} else {
								if (token) {
									showError('Ошибка входа', 'Неверный пользователь');
								}
								setUser(null);
								if (typeof callback === 'function') {
									callback(false);
								}
							}
						} else {
							showError('Ошибка данных', 'Некорректные данные с сервера');
							setUser(null);
							if (typeof callback === 'function') {
								callback(false);
							}
						}
					})
					.catch((error) => {
						if (error.name !== 'AbortError') {
							if (error.status === 401) {
								setUser(null);
								removeStorageToken();
							}
							try {
								error
									.json()
									.then((reason) => {
										if (reason.goTo) {
											if (reason.reload) {
												window.location.replace(reason.goTo);
											} else if (reason.goTo === '/user/login/') {
												window.location.replace('/login');
											} else {
												window.location.replace(reason.goTo);
											}
										} else if (reason.error === '404 Not Found') {
											window.location.replace('/404', { replace: true });
										} else {
											showError('Ошибка', reason.error);
										}
									})
									.catch(() => {
										showError('Ошибка', 'Некорректный ответ сервера');
									});
							} catch (err) {
								showError('Ошибка', `${err}`);
							}
						}
						if (typeof callback === 'function') {
							callback(false);
						}
					});
			}
		},
		[controller.signal, isDev, navigate, removeStorageToken, showError, token],
	);

	// Обработчик логина
	const handleLogin = useCallback(
		(username, password, setIsLoading, callback) => {
			const formData = new FormData();
			formData.set('login', username);
			formData.set('password', password);
			fetch(
				`${
					isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL
				}/users/login/`,
				{
					signal: controller.signal,
					method: 'POST',
					body: formData,
				},
			)
				.then((response) => {
					if (response.ok) {
						if (response.redirected) {
							return {
								success: 'Auth ok',
							};
						}
						return response.json();
					}
					return Promise.reject(response);
				})
				.then((answer) => {
					if (answer?.success === 'Auth ok') {
						if (answer.session_id) {
							setToken(answer.session_id);
							setStorageToken(answer.session_id);
							if (authChannel) {
								authChannel.postMessage(answer.session_id);
							}
						}
						if (answer.reload) {
							window.location.assign(answer.goTo);
						} else if (answer.goTo) {
							callback(true);
							getUserData(callback);
							navigate(answer.goTo);
						} else {
							callback(true);
							getUserData(callback);
						}
					} else if (answer?.reload) {
						setToken(null);
						removeStorageToken();
						window.location.assign(answer.goTo);
						navigate(answer.goTo);
						callback(false);
					} else {
						setToken(null);
						removeStorageToken();
						setUser(null);
						callback(false);
					}
				})
				.catch((response) => {
					response
						.json()
						.then(() => {
							setToken(null);
							removeStorageToken();
							setUser(null);
							callback(false);
						})
						.catch(() => {
							setToken(null);
							removeStorageToken();
							setUser(null);
							callback(false);
						});
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[
			isDev,
			controller.signal,
			setStorageToken,
			authChannel,
			getUserData,
			navigate,
			removeStorageToken,
		],
	);

	// Обработчик регистрации нового юзера
	const handleSignUp = useCallback(
		(fields, hash, setSubmitting, callback) => {
			const body = {
				...Object.fromEntries(
					Object.entries(fields).map(([key, value]) => [
						key,
						typeof value === 'string' ? value.trim() || null : value,
					]),
				),
				organizations: fields.organizations.some((organization) => organization.value?.length > 0)
					? fields.organizations
							.filter((organization) => organization.value.length > 0)
							.map((organization) => parseInt(organization.value, 10))
					: [],
				phone: parseInt(fields.phone, 10),
			};
			fetch(
				`${
					isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL
				}/users/invite${token ? `/?token=${token}` : '/'}`,
				{
					signal: controller.signal,
					method: 'POST',
					body: JSON.stringify(body),
				},
			)
				.then((response) => {
					if (response.ok) {
						if (response.redirected) {
							return {
								success: 'Sign Up ok',
							};
						}
						return response.json();
					}
					return Promise.reject(response);
				})
				.then((answer) => {
					if (answer?.success) {
						if (answer.session_id) {
							setToken(answer.session_id);
							setStorageToken(answer.session_id);
							if (authChannel) {
								authChannel.postMessage(answer.session_id);
							}
						}
						if (answer.reload) {
							window.location.assign(answer.goTo);
						} else if (answer.goTo) {
							callback(true);
							getUserData(callback);
							navigate(answer.goTo);
						} else {
							callback(true);
							if (hash) {
								getUserData(callback);
							}
						}
					} else {
						callback(false, answer.error);
					}
				})
				.catch(() => {
					showError('Ошибка', 'Ошибка отправки данных');
				})
				.finally(() => {
					setSubmitting(false);
				});
		},
		[authChannel, controller.signal, getUserData, isDev, navigate, setStorageToken, showError, token],
	);

	// Обработчик разлогина
	const handleLogout = useCallback(() => {
		fetch(
			`${
				isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL
			}/users/logout/?token=${token}`,
			{
				signal: controller.signal,
			},
		)
			.then((response) => (response.ok ? response.json() : Promise.reject(response)))
			.then((result) => {
				if (result?.success === 'Exit ok') {
					setToken(null);
					removeStorageToken();
					setUser(null);
					if (authChannel) {
						authChannel.postMessage('');
					}
					if (result?.reload) {
						window.location.assign(result.goTo);
					} else if (result?.goTo) {
						navigate(result.goTo);
					}
				}
			});
	}, [isDev, token, controller.signal, removeStorageToken, authChannel, navigate]);

	const value = useMemo(
		() => ({
			token,
			setToken,
			user,
			setUser,
			handleLogin,
			handleSignUp,
			handleLogout,
			getUserData,
		}),
		[token, user, handleLogin, handleSignUp, handleLogout, getUserData],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = ({ children }) => {
	const auth = useAuth();
	const currentLocation = useLocation();
	const [searchParams] = useSearchParams();
	const sendedToken = useMemo(() => searchParams.get('token'), [searchParams]);

	if (!auth.token && !sendedToken) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to='/login' state={{ from: currentLocation }} replace />;
	}

	return children;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

RequireAuth.propTypes = {
	children: PropTypes.node.isRequired,
};
